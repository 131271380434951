import { useContext } from 'react';
import PrivilegeContext from '../Contexts/PrivilegeContext'

export function usePrivileges(businessUnitCode) {
    const { admin, supervisor, canReview, canProcess, canGrade, setBusinessUnitCode } = useContext(PrivilegeContext)
    if (setBusinessUnitCode) {
        setBusinessUnitCode(businessUnitCode);
    }
    return { canProcess, canReview, canGrade, supervisor, admin };
}

export default usePrivileges;