import { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils'
import useAlert from '../../Alerts/useAlert';

const GetSiteAlert = () => {
    const { setAlert } = useAlert();
    const [siteAlert, setSiteAlert] = useState("");
    const [formAlert, setFormAlert] = useState("");

    useEffect(() => {
        fetchAndSetDataAuthenticated("api/v1/admin/siteAlert", "GET", null, setSiteAlert, setAlert);
    }, []);

    const updateAlert = () => {
        fetchAndSetDataAuthenticated("api/v1/admin/siteAlert", "PUT", `"${formAlert}"`, setSiteAlert, setAlert);
    }

    return (
        <>
            <Row>
                <Col>
                    {siteAlert || "No Alert Found" }
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Form>
                    <Form.Group>
                        <Form.Label><strong>Set Alert</strong></Form.Label>
                        <Form.Control variant="text" value={formAlert} onChange={(e) => setFormAlert(e.target.value) } placeholder="Enter new alert" />
                    </Form.Group>
                </Form>
            </Row>
            <Button type="submit" className="ms-auto mt-auto" onClick={updateAlert}>Set</Button>
        </>

    )
}

export default GetSiteAlert;