import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import PrivilegeContext from './Contexts/PrivilegeContext';


export type ProtectedRouteProps = {
    privilege: Privilege,
    children: React.ReactNode,
};

export type Privilege = "admin" | "supervisor" | "canProcess" | "canReview" | "canGrade";

const ProtectedRoute = ({ privilege, children }: ProtectedRouteProps) => {
    const { admin, supervisor, canProcess, canReview, canGrade } = useContext(PrivilegeContext);
    let hasPermission: boolean;

    switch (privilege) {
        case "admin":
            hasPermission = !!admin;
            break;
        case "supervisor":
            hasPermission = !!supervisor; 
            break;
        case "canProcess":
            hasPermission = !!canProcess; 
            break;
        case "canReview":
            hasPermission = !!canReview; 
            break;
        case "canGrade":
            hasPermission = !!canGrade; 
            break;
    }
    if (!hasPermission) {
        return <Navigate to="/" />;
    }
    return children;
}

export default ProtectedRoute