import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils';
import useAlert from '../../Alerts/useAlert';

const GetLotsWithMostRevisions = () => {
    const { setAlert } = useAlert();
    const [lots, setLots] = useState();

    useEffect(() => {
        getRevisions();
    }, []);

    const getRevisions = () =>
    {
        fetchAndSetDataAuthenticated("api/v1/admin/lotsWithRevisions/10", "GET", null, setLots, setAlert);
    }

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Lot Number</th>
                        <th>Lot Revisions</th>
                    </tr>
                </thead>
                <tbody>
                    {lots && lots.map((lot, index) =>
                            <tr key={`${lot.lotNumber}`}>
                                <td>{lot.lotNumber}</td>
                                <td>{lot.numberOfRevisions}</td>
                            </tr>
                        )}
                </tbody>
            </Table>
            <div className="mt-auto d-flex">
                <Button onClick={getRevisions}>Refresh</Button>
            </div>
        </>

    )
}

export default GetLotsWithMostRevisions;