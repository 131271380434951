import React, { useState, useEffect } from 'react';
import { Nav, Form } from 'react-bootstrap';

import DataTable from '../../DataTable/datatable.component';
import NoFilter from '../../DataTable/datatable.nofilter';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import CommonConfigPage from './CommonConfigPage';
import RecipeEditDialog from '../../Dialogs/Configuration/RecipeEditDialog';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import { useBusinessUnits } from '../../Utilities/Hooks';

function RecipeDataTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipeData = async function () {
            let url = `api/v1/recipedata/?includeDeleted=${includeDeleted}`;
            setLoaded(false);
            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                })
                .catch(error => {
                    setErrored(true);
                })
                .finally(() => {
                    setLoaded(true);
                });
        };
        getRecipeData();
    }, [includeDeleted, businessUnitId, refresh]);

    const getRecipeForOnRowClick = async (recipeData) => {
        if (!recipeData.recipeId) {
            return recipeData;
        }
        let url = `api/v1/recipe/${recipeData.recipeId}`;
        let recipe = { recipeId: recipeData.recipeId };
        await fetchDataAuthenticated(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                recipe = data;
            })
            .catch(error => {
                recipe = null;
            });

        return onRowClick(recipe);
    }

    const tableColumns = [
        {
            Header: 'Recipe Name',
            accessor: 'recipeName',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={async () => await getRecipeForOnRowClick(props.row.original)}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Quality Name',
            accessor: 'qualityName',
            filter: 'includesString',
        },
        {
            Header: 'Analysis',
            accessor: 'analysis',
            filter: 'includesString',
        },
        {
            Header: 'Component',
            accessor: 'component',
            filter: 'includesString',
        },
        {
            Header: 'Recipe Property Name',
            accessor: 'recipePropertyName',
            filter: 'includesString',
        },
        {
            Header: 'Recipe Data Type Name',
            accessor: 'recipeDataTypeName',
            filter: 'includesString',
        },
        {
            Header: 'Min Value',
            accessor: 'minValue',
            Filter: NoFilter,
        },
        {
            Header: 'Target Value',
            accessor: 'targetValue',
            Filter: NoFilter,
        },
        {
            Header: 'Max Value',
            accessor: 'maxValue',
            Filter: NoFilter,
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    const initialState = {
        sortBy: [
            {
                id: 'name',
                desc: false
            }
        ]
    };
    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: initialState , getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored
            ? <p>There was a problem.</p>
            : <p><em>Loading...</em></p>;

    return tableMarkup;
}

function RecipeDataConfiguration() {
    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState(0);


    return (
        <CommonConfigPage
            title="Recipe Data"
            table={(includeDeleted, showEditDialog, refreshList) => <RecipeDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <RecipeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits} />}
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={(e) => { setBusinessUnitId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Business Units</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default RecipeDataConfiguration;