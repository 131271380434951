import { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils'
import useAlert from '../../Alerts/useAlert';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';

const GetSample = () => {
    const { setAlert } = useAlert();
    const [samples, setSample] = useState();
    const [sampleId, setSampleId] = useState("");

    const getSample = () => {
        fetchAndSetDataAuthenticated(`api/v1/admin/sample/${sampleId}`, "GET", null, setSample, setAlert);
    }

    return (
        <>
            {samples &&
                <>
                    <Row>
                        <Col>
                            Sample Id:
                        </Col>
                        <Col>
                            {samples[0].sampleId}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Sampled Date:
                        </Col>
                        <Col>
                            <DateAsLocaleString date={samples[0].sampledDate} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Tests in Sample:
                        </Col>
                        <Col>
                            {samples[0].tests.length}
                        </Col>
                    </Row>                                                                  
                </>
            }
            <Row className="mt-1">
                <Form>
                    <Form.Group>
                        <Form.Label><strong>Sample ID</strong></Form.Label>
                        <Form.Control type="text" value={sampleId} onChange={(e)=>setSampleId(e.target.value)} />
                    </Form.Group>
                </Form>
            </Row>
            <Button type="submit" className="ms-auto mt-auto" onClick={getSample}>Get</Button>
        </>
    )
}

export default GetSample;