import { Container, Tab, Nav, NavDropdown } from 'react-bootstrap';
import { useState } from 'react';
import BusinessUnits from './Configuration/BusinessUnits';
import ConfigurationData from './Configuration/ConfigurationData';
import EquipmentTypes from './Configuration/EquipmentTypes';
import Equipment from './Configuration/Equipment';
import Lines from './Configuration/Lines';
import Qualities from './Configuration/Qualities';
import LineQualityData from './Configuration/LineQualityData';
import LabResultsConfig from './Configuration/LabResultsConfig';
import RecipeTypes from './Configuration/RecipeTypes';
import RecipeProperties from './Configuration/RecipeProperties';
import RecipeDataTypes from './Configuration/RecipeDataTypes';
import RecipeData from './Configuration/RecipeData';
import Recipes from './Configuration/Recipes';
import GradedReasons from './Configuration/GradedReasons';
import Emails from './Configuration/Emails';
import ChangeLog from './ChangeLog';

import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../Auth/usePrivileges';

function Configuration() {

    const { t } = useTranslation();
    const { admin } = usePrivileges();

    let configTabData = [
        { eventKey: "businessUnits", title: t("Configuration.Tabs.BusinessUnits"), component: <BusinessUnits/> },
        { eventKey: "configData", title: t('Configuration.Tabs.ConfigurationData'), component: <ConfigurationData/> },
        { eventKey: "equipmentTypes",title: t('Configuration.Tabs.EquipmentTypes'), component: <EquipmentTypes/> },
        { eventKey: "equipment",title: t('Configuration.Tabs.Equipment'), component: <Equipment/> },
        { eventKey: "lines",title: t('Configuration.Tabs.Lines'), component: <Lines/>},
        { eventKey: "qualities", title: t('Configuration.Tabs.Qualities'), component: <Qualities/>},
        { eventKey: "lineQualityData", title: t('Configuration.Tabs.LineQualityData'), component: <LineQualityData/> },
        { eventKey: "labResultsConfig", title: t('Configuration.Tabs.LabResultsConfig'), component: <LabResultsConfig/> },
        { eventKey: "recipeTypes", title: t('Configuration.Tabs.RecipeTypes'), component: <RecipeTypes/> },
        { eventKey: "recipeProperties", title: t('Configuration.Tabs.RecipeProperties'), component: <RecipeProperties/> },
        { eventKey: "recipeDataTypes", title: t('Configuration.Tabs.RecipeDataTypes'), component: <RecipeDataTypes /> },
        { eventKey: "recipeData", title: t('Configuration.Tabs.RecipeData'), component: <RecipeData /> },
        { eventKey: "recipes", title: t('Configuration.Tabs.Recipes'), component: <Recipes /> },
        { eventKey: "gradedReasons", title: t('Configuration.Tabs.GradedReasons'), component: <GradedReasons/> },
        { eventKey: "emails", title: t('Configuration.Tabs.Emails'), component: <Emails/> }
    ]

    if (admin) {
        configTabData.push({eventKey: "changeLog", title: t('Configuration.Tabs.ChangeLog'), component: <ChangeLog/>})
    }

    const [selectedTab, setSelectedTab] = useState(configTabData[0].title);

    return (
        <Container fluid>
            <h3 className="ms-2">Configuration</h3>
            <Tab.Container defaultActiveKey={configTabData[0].eventKey} id="settings-tabs" mountOnEnter={true} justify>
                <Nav variant="tabs" className="tabs mt-3 mb-3">
                    <NavDropdown title={selectedTab} className="fs-5">
                        {configTabData.map(tabData =>
                            <NavDropdown.Item eventKey={tabData.eventKey} onClick={() => setSelectedTab(tabData.title)}>
                                {tabData.title}
                            </NavDropdown.Item>
                        )}
                    </NavDropdown>
                </Nav>
                <Tab.Content>
                    {configTabData.map(tabData =>
                        <Tab.Pane eventKey={tabData.eventKey}>{tabData.component}</Tab.Pane>
                    )}
                </Tab.Content>
            </Tab.Container>
        </Container >
    );
}

export default Configuration;