import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useInterval } from 'usehooks-ts';
import useLotDetails from './useLotDetails';
import { Helmet } from 'react-helmet-async';

import LotDetails from './LotDetails';
import LotDetails_BAPP from './LotDetails_BAPP';
import LotDetails_HCC from './LotDetails_HCC';

function LotDetailsSelector() {
    
    const { lotNumber } = useParams();
    const { lotDetail, getLotDetail, setLotDetail, loading, setLoading, checkForLotChanges } = useLotDetails(lotNumber);

    // refresh lot details
    useInterval(async () => {
        if (lotDetail) {
            let lotHasChanged = await checkForLotChanges(
                lotNumber,
                lotDetail.revisions[lotDetail.revisions.length - 1].lotRevisionId,
                lotDetail.summary.currentStatus,
                lotDetail.summary.processingStatus,
                lotDetail.gradingComments.length);

            if (lotHasChanged) {
                console.log("The lot has changed. Updating lot details..");
                getLotDetail();
            }
        }
    }, 20000);

    if (loading) {
        return (
            <div className="overlay-content">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{lotDetail.summary.lotNumber}</title>
            </Helmet>
            <LotDetailPage lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} />
        </>
    );
}

function LotDetailPage({ lotDetail, getLotDetail, setLotDetail, loading, setLoading }) {
    switch (lotDetail.summary.businessUnitCode) {
        case "BAPP_PP":
            return <LotDetails_BAPP lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} />
        case "HCC_PP":
        case "HCC_GPPE":
            return <LotDetails_HCC lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} supportsLotSplit={true} />
        case "HCC_LDPE":
            return <LotDetails_HCC lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} supportsLotSplit={false} />
        default:
            return <LotDetails lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} />
    }
}

export default LotDetailsSelector;