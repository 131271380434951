import React, { useEffect, useState } from "react";
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleExclamation, faCircleCheck, faCircleInfo, faComment } from '@fortawesome/free-solid-svg-icons';
import { fetchDataAuthenticated } from './Utilities/HttpUtils';

export type Variant = "danger" | "success" | "warning" | "info" | "primary" | "secondary";

export interface AlertBannerProps {
    variant: Variant,
}

export default function AlertBanner({ variant }: AlertBannerProps) {
    const [siteAlert, setSiteAlert] = useState("");

    useEffect(() => {
        getSiteAlert();
        let delay: number = 1000 * 60 * 2;
        const interval = setInterval(() => {
            getSiteAlert();
        }, delay)

        return () => clearInterval(interval);
    }, [])

    function GetVariantIcon(variant: Variant) {
        switch (variant) {
            case "danger":
                return faCircleExclamation;
            case "warning":
                return faTriangleExclamation;
            case "success":
                return faCircleCheck;
            case "info":
                return faCircleInfo;
            default:
                return faComment;
        }
    }

    function getSiteAlert() {
        let url: string = "api/v1/admin/siteAlert";
        fetchDataAuthenticated(url)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setSiteAlert(data);
                    })
                }
            })
            .catch(error => console.error(error.message));
    }

    return (
        <Alert variant={variant} className={"fs-5"} show={!!siteAlert}>
            <FontAwesomeIcon
                icon={GetVariantIcon(variant)}
                className="me-3"
            />
                {siteAlert}
        </Alert>
    )
}
