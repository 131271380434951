import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import CorporateDlg from './Dialogs/FooterDialogs/CorporateDlg';
import SupportDlg from './Dialogs/FooterDialogs/SupportDlg';
import AboutDlg from './Dialogs/FooterDialogs/AboutDlg';
import { Container, Row, Col } from 'react-bootstrap';
import ConfigurationContext from './Contexts/ConfigurationContext';
import { fetchDataAuthenticated } from './Utilities/HttpUtils';

function Footer() {

    const { t, i18n } = useTranslation();
    const configurationContext = useContext(ConfigurationContext);

    const [loading, setLoading] = useState(true);
    const [ userData, setUserData ] = useState();
    const [version, setVersion] = useState("");
    const [copywriteInfo, setCopywriteInfo] = useState("");
    const [timezone, setTimezone] = useState();
    const [languages, setLanguages] = useState(["En"]);
    const [corporateDlgIsOpen, setCorporateDlgIsOpen] = useState(false);
    const [supportDlgIsOpen, setSupportDlgIsOpen] = useState(false);
    const [aboutDlgIsOpen, setAboutDlgIsOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchDataAuthenticated('api/v1/user')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setUserData({
                    userName: data.userName,
                    privileges: data.privileges
                });
            })
            .catch(error => {
                console.log("Failed to retrieve user info");
                setUserData({
                    ...userData,
                    username: "<unknown>",
                    privileges: []

                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    /* Show the timezone in the footer or "Not set" if no configuration found */
    useEffect(() => {
        if (configurationContext.configurationData.length > 0) {
            let timezoneConfig = configurationContext.configurationData.find(element => element.name === "TimeZoneName");

            if (timezoneConfig) {
                setTimezone(timezoneConfig.value);
            } else {
                console.warn("Timezone not found");
                setTimezone("Not set");
            }
        }
    }, [configurationContext]);

    /* List the available languages as links in the footer, or default to En  */
    useEffect(() => {

        if (configurationContext.configurationData.length > 0) {

            let languagesConfig = configurationContext.configurationData.find(element => element.name === "Languages");

            if (languagesConfig) {
                setLanguages(languagesConfig.value.split(','));
            } else {
                console.warn("Languages not found. Default to En");
            }
        }
    }, [configurationContext]);

    useEffect(() => {
        fetchDataAuthenticated('api/info/app')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setVersion(data.version);
                setCopywriteInfo(data.copywriteInfo);
            })
            .catch(error => {
                console.log("Failed to retrieve app info");
            });
    }, []);

    const toggleCorporateDlg = () => {
        setCorporateDlgIsOpen(!corporateDlgIsOpen);
    };

    const toggleSupportDlg = () => {
        setSupportDlgIsOpen(!supportDlgIsOpen);
    };

    const toggleAboutDlg = () => {
        setAboutDlgIsOpen(!aboutDlgIsOpen);
    };

    return (
        loading
            ? <Spinner />
            : <>
                <Container fluid className="footer border-top">
                    <Row>
                        <Col className="d-flex justify-content-start">
                            <Link className="link me-2" to='#' onClick={toggleAboutDlg}>{t('Footer.About')}</Link>
                            <Link className="link me-3" to='#' onClick={toggleSupportDlg}>{t('Footer.Support')}</Link>
                            {languages.map((lng, index) => (
                                <Link className="link me-2" to='#' key={index}
                                    style={{ fontWeight: i18n.resolvedLanguage.toLowerCase() === lng.toLowerCase() ? 'bold' : 'normal' }}
                                    onClick={() => i18n.changeLanguage(lng)}>
                                    {lng}
                                </Link>
                            ))}
                        </Col>
                        <Col>
                            {timezone &&
                                <p><strong>{t('Footer.CurrentTimezone')}: </strong> {timezone}</p>
                            }
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end">
                                <Link className="link me-2" to='#' onClick={toggleCorporateDlg}>{t('Footer.CorporateSeparatenessNotice')}</Link>
                                <p className="text-dark d-none d-md-inline-block">{copywriteInfo}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <CorporateDlg isOpen={corporateDlgIsOpen} toggleModal={toggleCorporateDlg} />
                <SupportDlg isOpen={supportDlgIsOpen} toggleModal={toggleSupportDlg} />
                <AboutDlg isOpen={aboutDlgIsOpen} toggleModal={toggleAboutDlg} userName={userData.userName}
                        privileges={userData.privileges} version={version} copywrite={copywriteInfo} />
            </>
    )
}

export default Footer;