import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';
import useAlert from '../../Alerts/useAlert';

const GetRecentSpecFiles = () => {
    const { setAlert } = useAlert();
    const [files, setFiles] = useState();

    useEffect(() => {
        getSpecFiles();
    }, []);

    const getSpecFiles = () => {
        fetchAndSetDataAuthenticated("api/v1/admin/specImports", "GET", null, setFiles, setAlert);
    }

    return (
        <>
            <Table className="w-100">
                <thead>
                    <tr>
                        <th>Business Unit</th>
                        <th>Message Last Recieved</th>
                    </tr>     
                </thead>
                <tbody>
                {files && files.map((file, index) =>
                        <tr key={`file-${index}`}>
                            <td>{file.businessUnit}</td>
                            <td><DateAsLocaleString date={file.messageSentAtLocalTime}/></td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button className="me-auto mt-auto" onClick={getSpecFiles}>Refresh</Button>
        </>

    )
}

export default GetRecentSpecFiles;