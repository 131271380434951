import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';
import useAlert from '../../Alerts/useAlert';

const GetHeartbeats = () => {
    const { setAlert } = useAlert();
    const [heartbeats, setHeartbeats] = useState();

    useEffect(() => {
        getHeatbeats();
    }, []);

    const getHeatbeats = () => {
        fetchAndSetDataAuthenticated("api/v1/admin/heartbeat", "GET", null, setHeartbeats, setAlert);
    }

    return (
        <>
            <Table className="w-100">
                <thead>
                    <tr>
                        <th>Business Unit</th>
                        <th>Message Last Recieved</th>
                    </tr>     
                </thead>
                <tbody>
                    {heartbeats && heartbeats.map((heartbeat, index) =>
                        <tr key={`heartbeat-${index}`}>
                            <td>{heartbeat.businessUnit}</td>
                            <td><DateAsLocaleString date={heartbeat.messageSentAtLocalTime}/></td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button className="me-auto mt-auto" onClick={getHeatbeats}>Refresh</Button>
        </>
    )
}

export default GetHeartbeats;