import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import YesNoSelectFilter from '../../DataTable/datatable.yesnoselectfilter';
import DateColumn from '../../DataTable/datatable.datecolumn';
import RecipeEditDialog from '../../Dialogs/Configuration/RecipeEditDialog';
import { Nav, Form } from 'react-bootstrap';
import { useBusinessUnits, useRecipeTypes, useEquipment } from '../../Utilities/Hooks';
import CommonConfigPage from './CommonConfigPage';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import { useContext } from 'react';
import ConfigurationContext from '../../Contexts/ConfigurationContext'

function RecipesTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {

    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipes = async function () {
            let url = `api/v1/recipe/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getRecipes();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Recipe Type',
            accessor: 'recipeTypeName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Equipment',
            accessor: 'equipmentName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Version',
            accessor: 'version',
            filter: 'includesString'
        },
        {
            Header: 'Prime?',
            accessor: d => d.isPrime === true ? "Yes" : "No",
            id: 'isPrime',
            Filter: YesNoSelectFilter
        },
        {
            Header: 'Pellet?',
            accessor: d => d.isPellet === true ? "Yes" : "No",
            id: 'isPellet',
            Filter: YesNoSelectFilter
        },
        {
            Header: 'Addpack',
            accessor: 'addpack',
            filter: 'includesString'
        },
        {
            Header: 'Reactor Grade',
            accessor: 'reactorGrade',
            filter: 'includesString'
        },
        {
            Header: 'Grade Code',
            accessor: 'gradeCode',
            filter: 'includesString'
        },
        {
            Header: 'Material Code',
            accessor: 'materialCode',
            filter: 'includesString'
        },
        {
            Header: 'Material Type',
            accessor: 'materialType',
            filter: 'includesString'
        },
        {
            Header: 'Last Change Account',
            accessor: 'lastChangeAccount',
            filter: 'includesString'
        },
        DateColumn({ header: 'Last Change', accessor: 'lastChange' }),
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    const initialState = {
        sortBy: [
            {
                id: 'name',
                desc: false
            }
        ]
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: initialState, getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
    : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function RecipeConfiguration() {

    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState(0);

    const recipeTypes = useRecipeTypes(false, businessUnitId);
    const [recipeTypeId, setRecipeTypeId] = useState(0);

    const equipment = useEquipment(false, businessUnitId);
    const [equipmentId, setEquipmentId] = useState(0);

    const configurationContext = useContext(ConfigurationContext);

    const [lastRecipeImportTime, setLastRecipeImportTime] = useState("");

    useEffect(() => {
        if (configurationContext.configurationData.length > 0) {
            let lastRecipeImportTimeConfig = configurationContext.configurationData.find(element => element.name === "LastRecipeImportTime");

            if (lastRecipeImportTimeConfig) {
                setLastRecipeImportTime(lastRecipeImportTimeConfig.value);
            } else {
                console.warn("LastRecipeImportTime not found");
                setLastRecipeImportTime("Not set");
            }
        }
    }, [configurationContext]);

    const getNewRecipe = () => {
        return { recipeId: null, name: "", businessUnitId: businessUnitId, equipmentId: equipmentId, recipeTypeId: recipeTypeId, isDeleted: false }
    };

    const changeBusinessUnitFilter = (e) => {
        setBusinessUnitId(e.currentTarget.value);
        setRecipeTypeId(0);
        setEquipmentId(0);
    }

    return (
        <CommonConfigPage
            title="Recipes"
            table={(includeDeleted, showEditDialog, refreshList) => <RecipesTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <RecipeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits} />}
            getNewConfigObject={getNewRecipe}
            pageMetaDataLabel={ "Last updated:" }
            pageMetaData={ lastRecipeImportTime }
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={changeBusinessUnitFilter}>
                <option value={0} key={0}>All Business Units</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
            <Form.Select type="text" value={recipeTypeId} className="filter-select float-start" onChange={(e) => { setRecipeTypeId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Recipe Types</option>
                {recipeTypes.map((rt) => <option value={rt.recipeTypeId} key={rt.recipeTypeId}>{rt.name}</option>)}
            </Form.Select>
            <Form.Select type="text" value={equipmentId} className="filter-select float-start" onChange={(e) => { setEquipmentId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Equipment</option>
                {equipment.map((e) => <option value={e.equipmentId} key={e.equipmentId}>{e.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default RecipeConfiguration;