import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useAlert from '../Alerts/useAlert';
import MultiselectBox from '../Utilities/MultiselectBox';
import { fetchDataAuthenticated } from '../Utilities/HttpUtils';
import { account } from '../Utilities/AuthUtils';

function UserPreferences() {

    const { t } = useTranslation();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);

    const [businessUnits, setBusinessUnits] = useState([]);
    const [lines, setLines] = useState();

    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState();
    const [selectedLines, setSelectedLines] = useState();


    useEffect(() => {
        getBusinessUnits();
        getLines();
    }, [selectedBusinessUnits]);

    useEffect(() => {
        if (account?.username && account.username !== "") {
            getUserPreferences();
        }
    }, [account]);

    async function getBusinessUnits() {
        fetchDataAuthenticated("api/v1/businessunit")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get business units");
            })
            .then(data => {
                setBusinessUnits(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    async function getLines() {

        let businessUnitIds = [];

        if (selectedBusinessUnits && selectedBusinessUnits.length > 0) {
            businessUnitIds = businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)).map(bu => bu.businessUnitId);
        } else {
            businessUnitIds = businessUnits.map(bu => bu.businessUnitId);
        }

        fetchDataAuthenticated(`api/v1/line/multiplebusinessunit?businessUnitIds=${businessUnitIds}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get lines");
            })
            .then(data => {
                setLines(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    async function getUserPreferences() {
        setLoading(true);

        await fetchDataAuthenticated("api/v1/userpreferences?username=" + account.username)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error(await response.json());
            })
            .then(data => {
                setSelectedBusinessUnits(data.defaultBusinessUnitCodes);
                setSelectedLines(data.defaultLines);
            })
            .catch(error => {
                setAlert("danger", "Error", "Failed to get user preferences. " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function saveChanges() {
        setLoading(true);

        let body = {
            defaultBusinessUnitCodes: selectedBusinessUnits,
            defaultLines: selectedLines
        }

        await fetchDataAuthenticated("api/v1/userpreferences/" + account.username, "PUT", JSON.stringify(body))
            .then(async response => {
                if (response.ok) {
                    return;
                }

                throw new Error(await response.json());
            })
            .then(data => {
                setAlert("success", "Success", "Saved user preferences");
            })
            .catch(error => {
                setAlert("danger", "Error", "Failed to save user preferences. " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return loading
        ? <div className="overlay-content">
            <Spinner animation="border" />
        </div>
        : <>
            <Container className="pb-5">
                <Row>
                    <Col>
                        <h3>{t('UserPreferences.Title')}</h3>
                    </Col>
                    <Col>
                        <p className="float-end">{account.username}</p>
                    </Col>
                </Row>

                <Container className="p-3 mb-3 bg-light rounded-3">
                    <Row className="mb-3">
                        <Col sm={4}>
                            <h5>Business Units</h5>
                        </Col>
                        <Col sm={8}>
                            <MultiselectBox
                                options={businessUnits}
                                selectedOptions={businessUnits && selectedBusinessUnits ? businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)) : []}
                                setSelectedOptions={setSelectedBusinessUnits}
                                map="code" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            <h5>Lines</h5>
                        </Col>
                        <Col sm={8}>
                            <MultiselectBox
                                options={lines}
                                selectedOptions={lines && selectedLines ? lines.filter(l => selectedLines.includes(l.productionLine)) : []}
                                setSelectedOptions={setSelectedLines}
                                map="productionLine" />
                        </Col>
                    </Row>
                    <div className="float-end">
                        <Button variant="primary" onClick={() => saveChanges(true)}>
                            {t('UserPreferences.SaveChanges')}
                        </Button>
                    </div>
                </Container>
            </Container>
        </>;
}

export default UserPreferences;