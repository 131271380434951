import { useState } from 'react';
import { Button, Row, Form, Table } from 'react-bootstrap';

import { fetchAndSetDataAuthenticated } from '../../Utilities/HttpUtils'
import { useBusinessUnits } from '../../Utilities/Hooks';
import useAlert from '../../Alerts/useAlert';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';

const GetQirs = () => {
    const businessUnits = useBusinessUnits(false);
    const { setAlert } = useAlert();
    const [qirs, setQirs] = useState();
    const [businessUnit, setBusinessUnit] = useState("");
    const [lotNumber, setLotNumber] = useState();

    const getQirs = () => {
        if (!businessUnit) {
            return;
        }

        let url = `api/v1/admin/qir/${businessUnit}?lotNumber=${lotNumber}`;
        fetchAndSetDataAuthenticated(url, "GET", null, setQirs, setAlert);
    }

    return (
        <>
            {qirs &&
                (qirs.length > 0
                ?<Table className="mt-1">
                    <thead>
                        <tr>
                            <th>Incident Date</th>
                            <th>Status</th>
                            <th>Go To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qirs.map(qir =>
                            <tr key={`qir-${qir.id}`}>
                                <td>
                                    <DateAsLocaleString date={qir.incidentDate} />
                                </td>
                                <td>
                                    {qir.status}
                                </td>
                                <td>
                                    <Button href={qir.qirUrl} target="_blank" rel="noreferrer">Go To</Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                : <Row className="ps-2 mt-1">No QIRS found for lot</Row>
                )
            }
   
            <Row className="mt-1">
                <Form>
                    <Form.Group>
                        <Form.Label><strong>Business Unit</strong></Form.Label>
                        <Form.Select type="text" className="config-select" value={businessUnit} onChange={(e)=>setBusinessUnit(e.target.value)}>
                            <option value="" key={0} >Please Select...</option>
                            {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label><strong>Lot Number</strong></Form.Label>
                        <Form.Control type="text" value={lotNumber} onChange={(e)=>setLotNumber(e.target.value)} />
                    </Form.Group>
                </Form>
            </Row>
            <Button type="submit" className="ms-auto mt-auto" onClick={getQirs}>Get</Button>
        </>
    )
}

export default GetQirs;