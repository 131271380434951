import { Button } from 'react-bootstrap';

import { fetchDataAuthenticated } from '../../Utilities/HttpUtils'
import useAlert from '../../Alerts/useAlert';

const SampleManagerApiHealthCheck = () => {
    const { setAlert } = useAlert();

    const getHealthCheck = () => {
        fetchDataAuthenticated("api/v1/admin/sampleManagerApi/health", "GET")
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Healthy", "Requested returned Ok");
                } else {
                    response.json().then(text => setAlert("danger", "Error", text))
                }
            });
    }

    const getPing = () => {
        fetchDataAuthenticated("api/v1/admin/sampleManagerApi/ping", "GET")
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Succesful", "Succesfully pinged SM-API");
                } else {
                    response.json().then(text => setAlert("danger", "Error", text))
                }
            });
    }

    return (
        <>
            <Button className="mt-2" onClick={getHealthCheck}>Health Check</Button>
            <Button className="mt-2" onClick={getPing}>Ping</Button>
        </>
    )
}

export default SampleManagerApiHealthCheck;