import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import GetHeartbeats from './Admin/GetHeartbeats';
import GetLotsWithMostRevisions from './Admin/GetLotsWithMostRevisions';
import GetRecentSpecFiles from './Admin/GetRecentSpecFiles';
import GetSiteAlert from './Admin/GetSiteAlert';
import GetQirs from './Admin/GetQir';
import GetSample from './Admin/GetSample';
import SampleManagerApiHealthCheck from './Admin/SampleManagerApiHealthCheck';

const Admin = () => {
    const { t } = useTranslation();
    const cards = [
        { title: t("Admin.Heartbeat.Title"), description: t("Admin.Heartbeat.Description"), component: <GetHeartbeats /> },
        { title: t("Admin.RecentSpecFiles.Title"), description: t("Admin.RecentSpecFiles.Description"), component: <GetRecentSpecFiles /> },
        { title: t("Admin.LotsWithMostRevisions.Title"), description: t("Admin.LotsWithMostRevisions.Description"), component: <GetLotsWithMostRevisions /> },
        { title: t("Admin.Qirs.Title"), description: t("Admin.Qirs.Description"), component: <GetQirs /> },
        { title: t("Admin.Samples.Title"), description: t("Admin.Samples.Description"), component: <GetSample /> },
        { title: t("Admin.SampleManagerApiChecks.Title"), description: t("Admin.SampleManagerApiChecks.Description"), component: <SampleManagerApiHealthCheck /> },
        { title: t("Admin.SiteAlert.Title"), description: t("Admin.SiteAlert.Description"), component: <GetSiteAlert /> },
    ];

    return (
        <Container>
            <Row xs={1} md={2} lg={3} xl={4} >
                {cards.map((card, index) =>
                    <Col className="pt-2" key={`card-${index}`}>
                        <Card className="h-100">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>
                                    {card.title}
                                </Card.Title>
                                <Card.Subtitle>
                                    {card.description }
                                </Card.Subtitle>
                                <div className="mt-2 flex-fill d-flex flex-column">
                                    {card.component}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>)}
            </Row>
        </Container>
    )
}

export default Admin